.customized-notification {
  padding: 16px;
}

.customized-notification.ant-notification-notice {
  background-color: #2f4151;
  left: 50px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.55);
}

.customized-notification
  .ant-notification-notice-with-icon
  .ant-notification-notice-message,
.customized-notification .ant-notification-notice-description {
  color: white;
  font-weight: 600;
}

.customized-notification .ant-notification-close-icon {
  color: white;
  margin-top: 6px;
}

.ant-notification {
  z-index: 1101;
}
