.customized-blocker-popup {
  position: relative;
  .ant-modal-body {
    padding: 32px;

    .close-icon {
      fill: #434b5d;
      cursor: pointer;
      position: absolute;
      top: 16px;
      right: 16px;
    }
  }
  .ant-modal-confirm-btns {
    display: none;
  }
}
